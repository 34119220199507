import type { contract } from '@samsys/shared';
import type { TsRestClient } from './ts-rest.service';
import {
  apiHandler,
  type InferBody,
  type InferFlatRequest,
  type InferParams,
  type InferQuery
} from '@/utils/ts-rest';
import type { S3UploadService } from './s3-upload.service';

export type CreatePlantProtectionProductServiceOptions = {
  tsRestClient: TsRestClient;
  s3UploadService: S3UploadService;
};

export const createPlantProtectionProductService = ({
  tsRestClient,
  s3UploadService
}: CreatePlantProtectionProductServiceOptions) => {
  return {
    getAll(query: InferQuery<typeof contract.plantProtectionProduct.all>) {
      return apiHandler(tsRestClient.plantProtectionProduct.all, {
        query
      });
    },

    getById(id: string) {
      return apiHandler(tsRestClient.plantProtectionProduct.byId, {
        params: { id }
      });
    },

    getAllMixes(
      query: InferQuery<typeof contract.plantProtectionProduct.allMixes>
    ) {
      return apiHandler(tsRestClient.plantProtectionProduct.allMixes, {
        query
      });
    },

    createMix(
      body: InferBody<typeof contract.plantProtectionProduct.createMix>
    ) {
      return apiHandler(tsRestClient.plantProtectionProduct.createMix, {
        body
      });
    },

    deleteMix({
      id
    }: InferFlatRequest<typeof contract.plantProtectionProduct.deleteMix>) {
      return apiHandler(tsRestClient.plantProtectionProduct.deleteMix, {
        params: { id }
      });
    },

    updateMix({
      id,
      ...body
    }: InferFlatRequest<typeof contract.plantProtectionProduct.updateMix>) {
      return apiHandler(tsRestClient.plantProtectionProduct.updateMix, {
        body,
        params: { id }
      });
    },

    async getAllTasks(
      query: InferQuery<typeof contract.plantProtectionProduct.allTasks>
    ) {
      const response = await apiHandler(
        tsRestClient.plantProtectionProduct.allTasks,
        {
          query
        }
      );

      response.items.forEach(item => {
        if (item.plannedStartDate) {
          item.plannedStartDate = new Date(item.plannedStartDate);
        }
        if (item.plannedEndDate) {
          item.plannedEndDate = new Date(item.plannedEndDate);
        }
        if (item.effectiveStartDate) {
          item.effectiveStartDate = new Date(item.effectiveStartDate);
        }
        if (item.effectiveEndDate) {
          item.effectiveEndDate = new Date(item.effectiveEndDate);
        }
      });

      return response;
    },

    createTask(
      body: InferBody<typeof contract.plantProtectionProduct.createTask>
    ) {
      return apiHandler(tsRestClient.plantProtectionProduct.createTask, {
        body
      });
    },

    updateTask({
      id,
      ...body
    }: InferFlatRequest<typeof contract.plantProtectionProduct.updateTask>) {
      return apiHandler(tsRestClient.plantProtectionProduct.updateTask, {
        body,
        params: { id }
      });
    },

    startTask({
      id
    }: InferParams<typeof contract.plantProtectionProduct.startTask>) {
      return apiHandler(tsRestClient.plantProtectionProduct.startTask, {
        params: { id }
      });
    },

    stopTask({
      id
    }: InferParams<typeof contract.plantProtectionProduct.stopTask>) {
      return apiHandler(tsRestClient.plantProtectionProduct.stopTask, {
        params: { id }
      });
    },

    async finalizeTask({
      id,
      pdf,
      drivers,
      client,
      sendMailToClient
    }: {
      id: string;
      drivers: string[];
      client: string;
      sendMailToClient: boolean;
      pdf: File;
    }) {
      const pdfResponse = await s3UploadService.uploadMultiple([pdf]);

      return apiHandler(tsRestClient.plantProtectionProduct.finalizeTask, {
        params: { id },
        body: { pdf: pdfResponse[0]!.id, drivers, client, sendMailToClient }
      });
    },

    deleteTask({
      id
    }: InferFlatRequest<typeof contract.plantProtectionProduct.deleteTask>) {
      return apiHandler(tsRestClient.plantProtectionProduct.deleteTask, {
        params: { id }
      });
    }
  };
};
