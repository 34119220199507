import type { contract } from '@samsys/shared';
import type { TsRestClient } from './ts-rest.service';
import { apiHandler, type InferBody, type InferParams } from '@/utils/ts-rest';

export const createTsDatadefService = ({
  tsRestClient
}: {
  tsRestClient: TsRestClient;
}) => {
  return {
    getByTargets(body: InferBody<typeof contract.tsDatadef.byWidgetTarget>) {
      return apiHandler(tsRestClient.tsDatadef.byWidgetTarget, { body });
    },

    addToFavorites({
      id
    }: InferParams<typeof contract.tsDatadef.addToFavorites>) {
      return apiHandler(tsRestClient.tsDatadef.addToFavorites, {
        params: { id }
      });
    },

    removeFromFavorites({
      id
    }: InferParams<typeof contract.tsDatadef.removeFromFavorites>) {
      return apiHandler(tsRestClient.tsDatadef.removeFromFavorites, {
        params: { id }
      });
    }
  };
};
