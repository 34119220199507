import { createQueryKeyStore } from '@lukemorales/query-key-factory';
import type {
  AnyFunction,
  PaginatedRequestDto,
  Prettify
} from '@samsys/shared';
import { type MaybeRefOrGetter, toValue } from 'vue';

type PaginatedParams<
  TFn extends (arg: any) => any,
  TOmit extends string = ''
> = Parameters<TFn>[0] extends PaginatedRequestDto
  ? MaybeRefOrGetter<Prettify<Omit<Parameters<TFn>[0], 'page' | TOmit>>>
  : never;

type Params<
  TFn extends (arg: any) => any,
  TOmit extends string = ''
> = MaybeRefOrGetter<Omit<Parameters<TFn>[0], TOmit>>;

const toQueryKey = <T>(v: MaybeRefOrGetter<T>) => computed(() => toValue(v));

export type UseQueryKeys = ReturnType<typeof useQueryKeys>;
type ToValue<T> = T extends MaybeRefOrGetter<infer U> ? U : never;

export type QueryKeyFunctionArgs<TPath extends string> =
  TPath extends `${infer Head}.${infer Tail}`
    ? Head extends keyof UseQueryKeys
      ? Tail extends keyof UseQueryKeys[Head]
        ? UseQueryKeys[Head][Tail] extends AnyFunction
          ? ToValue<Parameters<UseQueryKeys[Head][Tail]>[0]>
          : never
        : never
      : never
    : never;

export const useQueryKeys = () => {
  const api = useApi();

  return createQueryKeyStore({
    activities: {
      list: (arg: PaginatedParams<typeof api.activityService.getAll>) => ({
        queryKey: [toQueryKey(arg)],
        queryFn: ctx =>
          api.activityService.getAll({
            ...toValue(arg),
            page: ctx.pageParam ?? 1
          })
      }),
      tsDatadef: (arg: Params<typeof api.activityService.getTsDataDef>) => ({
        queryKey: [toQueryKey(arg)],
        queryFn: () => api.activityService.getTsDataDef(toValue(arg))
      }),

      tsDatadefStats: (
        arg: Params<typeof api.activityService.getTsDataDefStats>
      ) => ({
        queryKey: [toQueryKey(arg)],
        queryFn: () => api.activityService.getTsDataDefStats(toValue(arg))
      }),

      assignments: (
        arg: PaginatedParams<typeof api.activityService.getAllAssignments>
      ) => ({
        queryKey: [toQueryKey(arg)],
        queryFn: ctx =>
          api.activityService.getAllAssignments({
            ...toValue(arg),
            page: ctx.pageParam ?? 1
          })
      }),

      liveMachines: (
        arg: Params<typeof api.activityService.getLiveMachines>
      ) => ({
        queryKey: [toQueryKey(arg)],
        queryFn: () => api.activityService.getLiveMachines(toValue(arg))
      })
    },

    activityBookings: {
      list: (
        query: PaginatedParams<typeof api.activityBookingService.getAll>
      ) => ({
        queryKey: [toQueryKey(query)],
        queryFn: ctx =>
          api.activityBookingService.getAll({
            ...toValue(query),
            page: ctx.pageParam ?? 1
          })
      }),
      conflicts: (
        arg: Params<typeof api.activityBookingService.getConflicts>
      ) => ({
        queryKey: [toQueryKey(arg)],
        queryFn: () => api.activityBookingService.getConflicts(toValue(arg))
      }),
      attributions() {
        return {
          queryKey: ['attributions'],
          queryFn: () => api.activityBookingService.getAttributions()
        };
      }
    },

    activityTemplates: {
      list: {
        queryKey: null,
        queryFn: api.activityTemplateService.getAll
      }
    },

    areas: {
      list: (query: PaginatedParams<typeof api.areaService.getAll>) => ({
        queryKey: [toQueryKey(query)],
        queryFn: ctx =>
          api.areaService.getAll({
            ...toValue(query),
            page: ctx.pageParam ?? 1
          })
      }),
      simpleList: (query: PaginatedParams<typeof api.areaService.getList>) => ({
        queryKey: [toQueryKey(query)],
        queryFn: ctx =>
          api.areaService.getList({
            ...toValue(query),
            page: ctx.pageParam ?? 1
          })
      }),
      openData: (query: Params<typeof api.areaService.getOpenData>) => ({
        queryKey: [toQueryKey(query)],
        queryFn: () => api.areaService.getOpenData(toValue(query))
      }),

      landRegister: (
        query: Params<typeof api.areaService.getLandRegister>
      ) => ({
        queryKey: [toQueryKey(query)],
        queryFn: () => api.areaService.getLandRegister(toValue(query))
      })
    },

    associations: {
      conflicts: (
        query: Params<typeof api.databoxAssociationService.getConflicts>
      ) => ({
        queryKey: [toQueryKey(query)],
        queryFn: () =>
          api.databoxAssociationService.getConflicts(toValue(query))
      }),

      simpleList: (query: PaginatedParams<typeof api.areaService.getList>) => ({
        queryKey: [toQueryKey(query)],
        queryFn: ctx =>
          api.areaService.getList({
            ...toValue(query),
            page: ctx.pageParam ?? 1
          })
      }),
      openData: (query: Params<typeof api.areaService.getOpenData>) => ({
        queryKey: [toQueryKey(query)],
        queryFn: () => api.areaService.getOpenData(toValue(query))
      })
    },

    bleTags: {
      list: (query: PaginatedParams<typeof api.bleTagService.getAll>) => ({
        queryKey: [toQueryKey(query)],
        queryFn: ctx =>
          api.bleTagService.getAll({
            ...toValue(query),
            page: ctx.pageParam ?? 1
          })
      })
    },

    canbusCounterConfig: {
      profiles: (
        query: PaginatedParams<
          typeof api.canbusCounterConfigService.getProfiles
        >
      ) => ({
        queryKey: [toQueryKey(query)],
        queryFn: ctx =>
          api.canbusCounterConfigService.getProfiles({
            ...toValue(query),
            page: ctx.pageParam ?? 1
          })
      })
    },

    canbusValuesConfig: {
      byCounter: (
        params: Params<typeof api.canbusValuesConfigService.getByCounter>
      ) => ({
        queryKey: [toQueryKey(params)],
        queryFn: () =>
          api.canbusValuesConfigService.getByCounter(toValue(params))
      })
    },

    clusters: {
      list: (query: PaginatedParams<typeof api.clusterService.getAll>) => ({
        queryKey: [toQueryKey(query)],
        queryFn: ctx =>
          api.clusterService.getAll({
            ...toValue(query),
            page: ctx.pageParam ?? 1
          })
      }),

      byId: (clusterId: MaybeRefOrGetter<string>) => ({
        queryKey: [toQueryKey(clusterId)],
        queryFn: () =>
          api.clusterService.getById({ clusterId: toValue(clusterId) }),
        contextQueries: {
          members: (
            query: PaginatedParams<
              typeof api.clusterService.getMembers,
              'clusterId'
            >
          ) => {
            return {
              queryKey: [toQueryKey(query)],
              queryFn: ctx =>
                api.clusterService.getMembers({
                  clusterId: toValue(clusterId),
                  ...toValue(query),
                  page: ctx.pageParam ?? 1
                })
            };
          },

          machines: (
            query: PaginatedParams<
              typeof api.clusterService.getMachines,
              'clusterId'
            >
          ) => {
            return {
              queryKey: [toQueryKey(query)],
              queryFn: ctx =>
                api.clusterService.getMachines({
                  clusterId: toValue(clusterId),
                  ...toValue(query),
                  page: ctx.pageParam ?? 1
                })
            };
          }
        }
      }),

      invites: (query: Params<typeof api.clusterService.getInvites>) => {
        return {
          queryKey: [toValue(query)],
          queryFn: () => api.clusterService.getInvites(toValue(query))
        };
      }
    },

    databox: {
      list: (query: PaginatedParams<typeof api.dataBoxService.getAll>) => ({
        queryKey: [toQueryKey(query)],
        queryFn: ctx =>
          api.dataBoxService.getAll({
            ...toValue(query),
            page: ctx.pageParam ?? 1
          })
      }),

      simpleList: (
        query: PaginatedParams<typeof api.dataBoxService.getList>
      ) => ({
        queryKey: [toQueryKey(query)],
        queryFn: ctx =>
          api.dataBoxService.getList({
            ...toValue(query),
            page: ctx.pageParam ?? 1
          })
      }),

      licences: (
        query: PaginatedParams<typeof api.dataBoxService.getBillingLicences>
      ) => ({
        queryKey: [toQueryKey(query)],
        queryFn: ctx =>
          api.dataBoxService.getBillingLicences({
            ...toValue(query),
            page: ctx.pageParam ?? 1
          })
      }),

      associations: (
        query: PaginatedParams<
          typeof api.databoxAssociationService.getAllByDataboxId
        >
      ) => ({
        queryKey: [toQueryKey(query)],
        queryFn: ctx =>
          api.databoxAssociationService.getAllByDataboxId({
            ...toValue(query),
            page: ctx.pageParam ?? 1
          })
      }),

      hashtags: {
        queryKey: null,
        queryFn: () => api.dataBoxService.getAvailableHashtags()
      },

      byId: (databoxId: MaybeRefOrGetter<string>) => ({
        queryKey: [toQueryKey(databoxId)],
        queryFn: () =>
          api.dataBoxService.getById({ databoxId: toValue(databoxId) })
      }),

      alerts: (databoxId: MaybeRefOrGetter<string>) => ({
        queryKey: [toQueryKey(databoxId), 'alerts'],
        queryFn: () =>
          api.dataBoxService.getAlerts({ databoxId: toValue(databoxId) })
      }),

      tsDatadefs: (databoxId: MaybeRefOrGetter<string>) => ({
        queryKey: [toQueryKey(databoxId), 'tsDatadefs'],
        queryFn: () =>
          api.dataBoxService.getDatadef({ databoxId: toValue(databoxId) })
      }),

      tsDatas: (query: Params<typeof api.dataBoxService.getTsDatas>) => ({
        queryKey: [toQueryKey(query)],
        queryFn: () => api.dataBoxService.getTsDatas(toValue(query))
      })
    },

    favoriteDashboards: {
      all: {
        queryKey: null,
        queryFn: () => api.meService.getFavoriteDashboards()
      }
    },

    favoriteFilters: {
      byKey(query: Params<typeof api.favoriteFilterService.getByKey>) {
        return {
          queryKey: [toQueryKey(query)],
          queryFn: () => api.favoriteFilterService.getByKey(toValue(query))
        };
      }
    },

    fields: {
      byUserIds: (userIds: MaybeRefOrGetter<string[]>) => ({
        queryKey: [toQueryKey(userIds)],
        queryFn: () =>
          api.fieldService.getAllByUserIds({ userIds: toValue(userIds) })
      }),

      tags: {
        queryKey: null,
        queryFn: () => api.fieldService.getAvailableTags()
      },

      hashtags: {
        queryKey: null,
        queryFn: () => api.fieldService.getAvailableHashtags()
      }
    },

    fileExports: {
      list: (query: PaginatedParams<typeof api.fileExportService.getAll>) => ({
        queryKey: [toQueryKey(query)],
        queryFn: ctx =>
          api.fileExportService.getAll({
            ...toValue(query),
            page: ctx.pageParam ?? 1
          })
      })
    },

    geoNotes: {
      list: (query: PaginatedParams<typeof api.geoNoteService.getAll>) => ({
        queryKey: [toQueryKey(query)],
        queryFn: ctx =>
          api.geoNoteService.getAll({
            ...toValue(query),
            page: ctx.pageParam ?? 1
          })
      })
    },

    machines: {
      list: (query: PaginatedParams<typeof api.machineService.getAll>) => ({
        queryKey: [toQueryKey(query)],
        queryFn: ctx =>
          api.machineService.getAll({
            ...toValue(query),
            page: ctx.pageParam ?? 1
          })
      }),

      simplList: (
        query: PaginatedParams<typeof api.machineService.getList>
      ) => ({
        queryKey: [toQueryKey(query)],
        queryFn: ctx =>
          api.machineService.getList({
            ...toValue(query),
            page: ctx.pageParam ?? 1
          })
      }),

      listDetailled: (
        query: PaginatedParams<typeof api.machineService.getAllDetailled>
      ) => ({
        queryKey: [toQueryKey(query)],
        queryFn: ctx =>
          api.machineService.getAllDetailled({
            ...toValue(query),
            page: ctx.pageParam ?? 1
          })
      }),

      tsDatas: (query: Params<typeof api.machineService.getTsDatas>) => ({
        queryKey: [toQueryKey(query)],
        queryFn: () => api.machineService.getTsDatas(toValue(query))
      }),

      associations: (
        query: PaginatedParams<typeof api.machineService.getAssociations>
      ) => ({
        queryKey: [toQueryKey(query)],
        queryFn: ctx =>
          api.machineService.getAssociations({
            ...toValue(query),
            page: ctx.pageParam ?? 1
          })
      }),

      rebuildStatus: (
        query: Params<typeof api.machineService.getRebuiltStatus>
      ) => ({
        queryKey: [toQueryKey(query)],
        queryFn: () => api.machineService.getRebuiltStatus(toValue(query))
      }),

      tags: {
        queryKey: null,
        queryFn: () => api.machineService.getAvailableTags()
      },

      hashtags: {
        queryKey: null,
        queryFn: () => api.machineService.getAvailableHashtags()
      },

      byId: (machineId: MaybeRefOrGetter<string>) => ({
        queryKey: [toQueryKey(machineId)],
        queryFn: () =>
          api.machineService.getById({ machineId: toValue(machineId) })
      }),

      byIdDetailled: (machineId: MaybeRefOrGetter<string>) => ({
        queryKey: [toQueryKey(machineId)],
        queryFn: () =>
          api.machineService.getByIdDetailled({ machineId: toValue(machineId) })
      }),

      schedule(query: Params<typeof api.machineService.getSchedule>) {
        return {
          queryKey: [toQueryKey(query)],
          queryFn: () => api.machineService.getSchedule(toValue(query))
        };
      },

      brands: {
        queryKey: null,
        queryFn: api.machineService.getBrands
      },

      categories: {
        queryKey: null,
        queryFn: api.machineService.getCategories
      },

      geolocationBatch(
        query: Params<typeof api.machineService.getGeolocationBatch>
      ) {
        return {
          queryKey: [toQueryKey(query)],
          queryFn: () => api.machineService.getGeolocationBatch(toValue(query))
        };
      },

      getUserAccessById(
        query: Params<typeof api.machineService.getUserAccessById>
      ) {
        return {
          queryKey: [toQueryKey(query)],
          queryFn: () => api.machineService.getUserAccessById(toValue(query))
        };
      },

      maintenanceActions(
        query: PaginatedParams<typeof api.machineService.getMaintenanceActions>
      ) {
        return {
          queryKey: [toQueryKey(query)],
          queryFn: ctx =>
            api.machineService.getMaintenanceActions({
              ...toValue(query),
              page: ctx.pageParam ?? 1
            })
        };
      },

      alertEstimates(
        query: Params<typeof api.machineService.getAlertEstimates>
      ) {
        return {
          queryKey: [toQueryKey(query)],
          queryFn: () => api.machineService.getAlertEstimates(toValue(query))
        };
      }
    },

    maintenanceActions: {
      listByMachine: (
        query: PaginatedParams<
          typeof api.maintenanceActionService.getAllByMachine
        >
      ) => ({
        queryKey: [toQueryKey(query)],
        queryFn: ctx =>
          api.maintenanceActionService.getAllByMachine({
            ...toValue(query),
            page: ctx.pageParam ?? 1
          })
      })
    },

    maintenanceAlerts: {
      list: (
        query: PaginatedParams<typeof api.maintenanceAlertService.getAll>
      ) => ({
        queryKey: [toQueryKey(query)],
        queryFn: ctx =>
          api.maintenanceAlertService.getAll({
            ...toValue(query),
            page: ctx.pageParam ?? 1
          })
      })
    },

    maintenanceTasks: {
      list: (
        query: PaginatedParams<typeof api.maintenanceTaskService.getAll>
      ) => ({
        queryKey: [toQueryKey(query)],
        queryFn: ctx =>
          api.maintenanceTaskService.getAll({
            ...toValue(query),
            page: ctx.pageParam ?? 1
          })
      })
    },

    maintenanceProfiles: {
      list: (
        query: PaginatedParams<typeof api.maintenanceProfileService.getAll>
      ) => ({
        queryKey: [toQueryKey(query)],
        queryFn: ctx =>
          api.maintenanceProfileService.getAll({
            ...toValue(query),
            page: ctx.pageParam ?? 1
          })
      })
    },

    plantProtectionProducts: {
      list: (
        query: PaginatedParams<typeof api.plantProtectionProductService.getAll>
      ) => ({
        queryKey: [toQueryKey(query)],
        queryFn: ctx =>
          api.plantProtectionProductService.getAll({
            ...toValue(query),
            page: ctx.pageParam ?? 1
          })
      }),

      mixList: (
        query: PaginatedParams<
          typeof api.plantProtectionProductService.getAllMixes
        >
      ) => ({
        queryKey: [toQueryKey(query)],
        queryFn: ctx =>
          api.plantProtectionProductService.getAllMixes({
            ...toValue(query),
            page: ctx.pageParam ?? 1
          })
      }),

      taskList: (
        query: PaginatedParams<
          typeof api.plantProtectionProductService.getAllTasks
        >
      ) => ({
        queryKey: [toQueryKey(query)],
        queryFn: ctx =>
          api.plantProtectionProductService.getAllTasks({
            ...toValue(query),
            page: ctx.pageParam ?? 1
          })
      }),

      byId: (id: MaybeRefOrGetter<string>) => ({
        queryKey: [toQueryKey(id)],
        queryFn: () => api.plantProtectionProductService.getById(toValue(id))
      })
    },

    staticDashboards: {
      list: (
        query: PaginatedParams<typeof api.staticDashboardService.getAll>
      ) => ({
        queryKey: [toQueryKey(query)],
        queryFn: ctx =>
          api.staticDashboardService.getAll({
            ...toValue(query),
            page: ctx.pageParam ?? 1
          })
      }),

      byId: (id: MaybeRefOrGetter<string>) => ({
        queryKey: [toQueryKey(id)],
        queryFn: () => api.staticDashboardService.getById(toValue(id))
      })
    },

    notifications: {
      list: (
        query: PaginatedParams<typeof api.notificationService.getAll>
      ) => ({
        queryKey: [toQueryKey(query)],
        queryFn: ctx =>
          api.notificationService.getAll({
            ...toValue(query),
            page: ctx.pageParam ?? 1
          })
      })
    },

    tsDataDefs: {
      byTargets: (query: Params<typeof api.tsDatadefService.getByTargets>) => ({
        queryKey: [toQueryKey(query)],
        queryFn: () => api.tsDatadefService.getByTargets(toValue(query))
      })
    },

    users: {
      me: {
        queryKey: null,
        queryFn: () => api.meService.me()
      },

      byId: (userId: MaybeRefOrGetter<string>) => ({
        queryKey: [toQueryKey(userId)],
        queryFn: () => api.userService.getById({ userId: toValue(userId) })
      }),

      tags: {
        queryKey: null,
        queryFn: () => api.userService.getAvailableTags()
      },

      hashtags: {
        queryKey: null,
        queryFn: () => api.userService.getAvailableHashtags()
      },

      list: (query: PaginatedParams<typeof api.userService.getAll>) => ({
        queryKey: [toQueryKey(query)],
        queryFn: ctx =>
          api.userService.getAll({
            ...toValue(query),
            page: ctx.pageParam ?? 1
          })
      }),

      simpleList: (query: PaginatedParams<typeof api.userService.getList>) => ({
        queryKey: [toQueryKey(query)],
        queryFn: ctx =>
          api.userService.getList({
            ...toValue(query),
            page: ctx.pageParam ?? 1
          })
      }),

      delegatedList: (
        query: PaginatedParams<typeof api.meService.getDelegatedUsers>
      ) => ({
        queryKey: [toQueryKey(query)],
        queryFn: ctx =>
          api.meService.getDelegatedUsers({
            ...toValue(query),
            page: ctx.pageParam ?? 1
          })
      }),

      schedule(query: Params<typeof api.userService.getSchedule>) {
        return {
          queryKey: [toQueryKey(query)],
          queryFn: () => api.userService.getSchedule(toValue(query))
        };
      }
    }
  });
};
