import type { DatePreset } from '@/components/ui/DatePicker/index.vue';
import {
  isAnonymous,
  type AnonymousResponse,
  type Nullable,
  isObject,
  type AnyFunction
} from '@samsys/shared';
import dayjs from 'dayjs';
import type { Composer } from 'vue-i18n';

export const unwrapPages = <T>(
  data: Nullable<{
    pages: {
      items: T[];
    }[];
  }>
) => {
  return data?.pages.flatMap(page => page.items) ?? [];
};

export const autoLoadAllPages = (
  query: {
    isFetching?: Ref<boolean>;
    hasNextPage?: Ref<Nullable<boolean>>;
    isFetchingNextPage: Ref<Nullable<boolean>>;
    fetchNextPage: AnyFunction;
    isError: Ref<boolean>;
  },
  enabled?: Ref<boolean>
) => {
  watchEffect(() => {
    if (enabled?.value === false) {
      return;
    }
    if (query.isFetching?.value) {
      return;
    }
    if (query.isError.value) {
      return;
    }
    if (query.isFetchingNextPage.value) {
      return;
    }

    query.hasNextPage?.value && query.fetchNextPage();
  });
};

export const filterAnonymous = <T extends AnonymousResponse>(data: T[]) =>
  data.filter(el => !isAnonymous(el)) as Exclude<T, AnonymousResponse>[];

const isArray = function (
  input: Record<string, unknown> | Record<string, unknown>[] | unknown
): input is Record<string, unknown>[] {
  return Array.isArray(input);
};

export const removeReadonly = function <T>(input: T): T {
  return (function recurse<
    K extends Record<string, unknown> | Record<string, unknown>[] | unknown
  >(input: K): K {
    if (isObject(input)) {
      return Object.fromEntries(
        Object.entries(input).map(([key, value]) => {
          return [key, recurse(value)];
        })
      ) as K;
    } else if (isArray(input)) {
      return input.map(i => recurse(i)) as K;
    }
    return input;
  })(input);
};

export const getTextFromMultilanguage = (locale: string, langArray: any[]) => {
  const lang = langArray.find(l => l.languages.includes(locale));
  if (lang) return lang.text;

  return langArray.find(l => l.languages.includes('default'))?.text || '';
};

export const hex2rgb = (hex: string) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  // return {r, g, b}
  return { r, g, b };
};

export type RGBColor = { r: number; g: number; b: number };
export const transparentize = ({ r, g, b }: RGBColor, opacity: number) =>
  `rgba(${r}, ${g}, ${b}, ${opacity})`;

export const urlBase64ToUint8Array = (base64String: string) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

export const randomHexcolor = () => {
  const hex = Math.floor(Math.random() * 16777215).toString(16);

  return `#${hex}`;
};

type Translator = Composer['t'];
export const datePresets = {
  singleStart(t: Translator) {
    return [
      {
        label: t('common.datePresets.single.today'),
        value: () => dayjs().toDate()
      },
      {
        label: t('common.datePresets.single.startOfWeek'),
        value: () => dayjs().startOf('week').toDate()
      },
      {
        label: t('common.datePresets.single.startOfMonth'),
        value: () => dayjs().startOf('month').toDate()
      },
      {
        label: t('common.datePresets.single.startOfYear'),
        value: () => dayjs().startOf('year').toDate()
      }
    ];
  },
  now(t: Translator) {
    return [
      {
        label: t('common.datePresets.single.now'),
        value: () => dayjs().toDate()
      }
    ];
  },
  singleFuture(t: Translator) {
    return [
      {
        label: t('common.datePresets.single.tomorrow'),
        value: () => dayjs().add(1, 'day').startOf('day').toDate()
      },
      {
        label: t('common.datePresets.single.startOfWeek'),
        value: () => dayjs().add(1, 'week').startOf('week').toDate()
      },
      {
        label: t('common.datePresets.single.startOfMonth'),
        value: () => dayjs().add(1, 'month').startOf('month').toDate()
      },
      {
        label: t('common.datePresets.single.startOfYear'),
        value: () => dayjs().add(1, 'year').startOf('year').toDate()
      }
    ];
  },
  singleEnd(t: Translator) {
    return [
      {
        label: t('common.datePresets.single.endOfDay'),
        value: () => dayjs().endOf('day').toDate()
      },
      {
        label: t('common.datePresets.single.endOfWeek'),
        value: () => dayjs().endOf('week').toDate()
      },
      {
        label: t('common.datePresets.single.endOfMonth'),
        value: () => dayjs().endOf('month').toDate()
      },
      {
        label: t('common.datePresets.single.endOfYear'),
        value: () => dayjs().endOf('year').toDate()
      }
    ];
  },
  rangeToNow(t: Translator) {
    return [
      {
        label: t('common.datePresets.range.today'),
        minValue: () => dayjs().startOf('day').toDate(),
        maxValue: () => dayjs().toDate()
      },
      {
        label: t('common.datePresets.range.startOfWeek'),
        minValue: () => dayjs().startOf('week').toDate(),
        maxValue: () => dayjs().toDate()
      },
      {
        label: t('common.datePresets.range.startOfMonth'),
        minValue: () => dayjs().startOf('month').toDate(),
        maxValue: () => dayjs().toDate()
      },
      {
        label: t('common.datePresets.range.startOfYear'),
        minValue: () => dayjs().startOf('year').toDate(),
        maxValue: () => dayjs().toDate()
      }
    ];
  },
  rangeToEnd(t: Translator) {
    return [
      {
        label: t('common.datePresets.range.today'),
        minValue: () => dayjs().startOf('day').toDate(),
        maxValue: () => dayjs().endOf('day').toDate()
      },
      {
        label: t('common.datePresets.range.startOfWeek'),
        minValue: () => dayjs().startOf('week').toDate(),
        maxValue: () => dayjs().endOf('week').toDate()
      },
      {
        label: t('common.datePresets.range.startOfMonth'),
        minValue: () => dayjs().startOf('month').toDate(),
        maxValue: () => dayjs().endOf('month').toDate()
      },
      {
        label: t('common.datePresets.range.startOfYear'),
        minValue: () => dayjs().startOf('year').toDate(),
        maxValue: () => dayjs().endOf('year').toDate()
      }
    ];
  }
} satisfies Record<string, (t: Translator) => DatePreset[]>;
