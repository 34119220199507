<script setup lang="ts">
const { t } = useI18n();

const { enable, isAvailable, isEnabled } = usePushNotifications();

const { data: me } = useMe();

const onAccept = () => {
  enable();
};

const onDecline = () => {
  lastSeenAt.value = new Date();
};

const lastSeenAt = useLocalStorage('webpushPromptLastSeenAt', null, {
  serializer: {
    read: value => (value ? new Date(value) : null),
    write: value => (value ? value.toISOString() : '')
  }
});

const dayjs = useDayjs();
const isOpened = computed(() => {
  if (!isAvailable.value || isEnabled.value) {
    return false;
  }
  if (!me.value) return false;
  if (!isDefined(lastSeenAt.value)) {
    return true;
  }

  return dayjs().diff(lastSeenAt.value, 'week') >= 1;
});
</script>

<template>
  <Transition>
    <UiSurface v-if="isOpened" class="web-push-prompt">
      {{ t('app.webpushPrompt.text') }}
      <UiFlex justify="flex-end" gap="3">
        <UiButton variant="ghost" @click="onDecline">
          {{ t('app.webpushPrompt.decline') }}
        </UiButton>
        <UiButton @click="onAccept">
          {{ t('app.webpushPrompt.accept') }}
        </UiButton>
      </UiFlex>
    </UiSurface>
  </Transition>
</template>

<style scoped lang="postcss">
@import '@/styles/medias.css';

.web-push-prompt {
  position: fixed;
  top: var(--size-4);
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  box-shadow: var(--shadow-4);
  max-width: var(--size-xs);
  display: grid;
  gap: var(--size-4);
  html.dark & {
    border: solid var(--border-size-1) var(--border);
  }

  @media (--md-n-below) {
    width: 100%;
  }

  &:is(.v-enter-active, .v-leave-active) {
    transition: transform 1.5s var(--ease-spring-4), opacity 1s var(--ease-4);
  }

  &:is(.v-enter-from, .v-leave-to) {
    transform: translateX(-50%) translateY(-100%);
    opacity: 0;
  }
}
</style>
