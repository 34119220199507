import type { contract, Override } from '@samsys/shared';
import type { TsRestClient } from './ts-rest.service';
import {
  apiHandler,
  type InferBody,
  type InferFlatRequest
} from '@/utils/ts-rest';
import type { S3UploadService } from './s3-upload.service';

export const createGeoNoteService = ({
  tsRestClient,
  s3UploadService
}: {
  tsRestClient: TsRestClient;
  s3UploadService: S3UploadService;
}) => {
  return {
    async create({
      audio,
      images,
      ...body
    }: Override<
      InferBody<typeof contract.geoNotes.create>,
      {
        images: File[];
        audio: File;
      }
    >) {
      const [audioResponse, imageResponses] = await Promise.all([
        s3UploadService.uploadMultiple([audio]),
        s3UploadService.uploadMultiple(images)
      ]);

      return apiHandler(tsRestClient.geoNotes.create, {
        body: {
          ...body,
          audio: audioResponse[0]!.id,
          images: imageResponses.map(resp => resp.id)
        }
      });
    },

    getAll(query: InferFlatRequest<typeof contract.geoNotes.all>) {
      return apiHandler(tsRestClient.geoNotes.all, { query });
    },

    delete({ id }: InferFlatRequest<typeof contract.geoNotes.delete>) {
      return apiHandler(tsRestClient.geoNotes.delete, { params: { id } });
    },

    update({ id, ...body }: InferFlatRequest<typeof contract.geoNotes.update>) {
      return apiHandler(tsRestClient.geoNotes.update, { params: { id }, body });
    }
  };
};
