export const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('/app/apps/client/src/pages/index.vue'),
    /* no children */
    meta: {
      "transition": "mobile-slide-right"
    }
  },
  {
    path: '/:404(.*)',
    name: 'NotFound',
    component: () => import('/app/apps/client/src/pages/[...404].vue'),
    /* no children */
    meta: {
      "layout": "unauthenticated"
    }
  },
  {
    path: '/activities',
    name: 'ActivitiesHome',
    components: {
      'default': () => import('/app/apps/client/src/pages/activities.vue'),
      'header': () => import('/app/apps/client/src/pages/activities@header.vue')
    },
    children: [
      {
        path: '',
        name: 'Activities',
        components: {
          'default': () => import('/app/apps/client/src/pages/activities/index.vue'),
          'header': () => import('/app/apps/client/src/pages/activities/index@header.vue')
        },
        /* no children */
        meta: {
          "featureFlags": [
            "ACTIVITIES",
            "ACTIVITIES"
          ],
          "transition": "mobile-slide-right"
        }
      },
      {
        path: 'assignments',
        name: 'ActivitiesAssignments',
        component: () => import('/app/apps/client/src/pages/activities/assignments.vue'),
        /* no children */
        meta: {
          "transition": "mobile-slide-right"
        }
      },
      {
        path: 'signals',
        name: 'SignalsStats',
        component: () => import('/app/apps/client/src/pages/activities/signals.vue'),
        /* no children */
        meta: {
          "transition": "mobile-slide-right"
        }
      }
    ],
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('/app/apps/client/src/pages/admin.vue'),
    /* no children */
    meta: {
      "featureFlags": [
        "ADMIN"
      ]
    }
  },
  {
    path: '/booking',
    name: 'Booking',
    component: () => import('/app/apps/client/src/pages/booking.vue'),
    children: [
      {
        path: '',
        name: 'BookingHome',
        component: () => import('/app/apps/client/src/pages/booking/index.vue'),
        /* no children */
      },
      {
        path: 'attribution',
        name: 'BookingAttributions',
        component: () => import('/app/apps/client/src/pages/booking/attribution.vue'),
        /* no children */
        meta: {
          "featureFlags": [
            "SIMPLE_BOOKINGS"
          ]
        }
      },
      {
        path: 'driver-timeline',
        name: 'DriverTimeline',
        component: () => import('/app/apps/client/src/pages/booking/driver-timeline.vue'),
        /* no children */
      },
      {
        path: 'machine-timeline',
        name: 'MachineTimeline',
        component: () => import('/app/apps/client/src/pages/booking/machine-timeline.vue'),
        /* no children */
      }
    ],
    meta: {
      "featureFlags": [
        "ACTIVITY_BOOKINGS"
      ],
      "transition": "mobile-slide-right"
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('/app/apps/client/src/pages/dashboard.vue'),
    children: [
      {
        path: '',
        name: 'DashboardList',
        component: () => import('/app/apps/client/src/pages/dashboard/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        name: 'DashboardDetail',
        component: () => import('/app/apps/client/src/pages/dashboard/[id].vue'),
        /* no children */
      }
    ],
    meta: {
      "transition": "mobile-slide-right"
    }
  },
  {
    path: '/devices',
    name: 'Devices',
    components: {
      'default': () => import('/app/apps/client/src/pages/devices.vue'),
      'header': () => import('/app/apps/client/src/pages/devices@header.vue')
    },
    children: [
      {
        path: '',
        name: 'DevicesHome',
        component: () => import('/app/apps/client/src/pages/devices/index.vue'),
        /* no children */
      },
      {
        path: 'ble-tag',
        name: 'BleTags',
        component: () => import('/app/apps/client/src/pages/devices/ble-tag.vue'),
        children: [
          {
            path: '',
            name: 'BleTagList',
            component: () => import('/app/apps/client/src/pages/devices/ble-tag/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'canbus-config',
        name: 'CanbusConfig',
        component: () => import('/app/apps/client/src/pages/devices/canbus-config.vue'),
        children: [
          {
            path: '',
            name: 'CanbusConfigProfiles',
            component: () => import('/app/apps/client/src/pages/devices/canbus-config/index.vue'),
            /* no children */
            meta: {
              "transition": "mobile-slide-right",
              "featureFlags": [
                "CANBUS_COUNTER_CONFIG"
              ]
            }
          }
        ],
      },
      {
        path: 'databox',
        name: 'Databoxes',
        components: {
          'default': () => import('/app/apps/client/src/pages/devices/databox.vue'),
          'header': () => import('/app/apps/client/src/pages/devices/databox@header.vue')
        },
        children: [
          {
            path: '',
            name: 'DataboxesList',
            components: {
              'default': () => import('/app/apps/client/src/pages/devices/databox/index.vue'),
              'header': () => import('/app/apps/client/src/pages/devices/databox/index@header.vue')
            },
            /* no children */
            meta: {
              "transition": "mobile-slide-right"
            }
          },
          {
            path: ':id',
            name: 'DataboxDetails',
            component: () => import('/app/apps/client/src/pages/devices/databox/[id].vue'),
            /* no children */
            meta: {
              "transition": "mobile-slide-right"
            }
          }
        ],
      },
      {
        path: 'licence',
        name: 'DataboxLicences',
        component: () => import('/app/apps/client/src/pages/devices/licence.vue'),
        children: [
          {
            path: '',
            name: 'DataboxLicencesList',
            component: () => import('/app/apps/client/src/pages/devices/licence/index.vue'),
            /* no children */
            meta: {
              "transition": "mobile-slide-right"
            }
          }
        ],
      }
    ],
  },
  {
    path: '/fields',
    name: 'FieldsList',
    component: () => import('/app/apps/client/src/pages/fields.vue'),
    /* no children */
  },
  {
    path: '/geo-notes',
    name: 'GeoNotes',
    component: () => import('/app/apps/client/src/pages/geo-notes.vue'),
    /* no children */
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('/app/apps/client/src/pages/login.vue'),
    /* no children */
    meta: {
      "layout": "unauthenticated",
      "public": true,
      "transition": "slide-right"
    }
  },
  {
    path: '/loginCode',
    name: 'loginCode',
    component: () => import('/app/apps/client/src/pages/loginCode.vue'),
    /* no children */
    meta: {
      "layout": "unauthenticated",
      "public": true,
      "transition": "slide-right"
    }
  },
  {
    path: '/lost-password',
    /* internal name: '/lost-password' */
    /* no component */
    children: [
      {
        path: '',
        name: 'LostPassword',
        component: () => import('/app/apps/client/src/pages/lost-password/index.vue'),
        /* no children */
        meta: {
          "layout": "unauthenticated",
          "transition": "slide-left",
          "public": true
        }
      },
      {
        path: 'success',
        name: 'LostPasswordSuccess',
        component: () => import('/app/apps/client/src/pages/lost-password/success.vue'),
        /* no children */
        meta: {
          "layout": "unauthenticated",
          "transition": "slide-left",
          "public": true
        }
      }
    ],
  },
  {
    path: '/machines',
    name: 'MachinesHome',
    components: {
      'default': () => import('/app/apps/client/src/pages/machines.vue'),
      'header': () => import('/app/apps/client/src/pages/machines@header.vue')
    },
    children: [
      {
        path: '',
        name: 'MachinesList',
        components: {
          'default': () => import('/app/apps/client/src/pages/machines/index.vue'),
          'header': () => import('/app/apps/client/src/pages/machines/index@header.vue')
        },
        /* no children */
        meta: {
          "transition": "mobile-slide-right"
        }
      },
      {
        path: ':id',
        name: 'MachineDetails',
        component: () => import('/app/apps/client/src/pages/machines/[id].vue'),
        /* no children */
        meta: {
          "transition": "mobile-slide-right"
        }
      },
      {
        path: 'signals',
        name: 'MachineSignals',
        component: () => import('/app/apps/client/src/pages/machines/signals.vue'),
        /* no children */
        meta: {
          "transition": "mobile-slide-right",
          "featureFlags": [
            "MACHINE_SIGNALS"
          ]
        }
      }
    ],
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: () => import('/app/apps/client/src/pages/maintenance.vue'),
    children: [
      {
        path: '',
        name: 'MaintenanceHome',
        component: () => import('/app/apps/client/src/pages/maintenance/index.vue'),
        /* no children */
        meta: {
          "transition": "mobile-slide-right"
        }
      },
      {
        path: 'alerts',
        name: 'MaintenanceAlerts',
        component: () => import('/app/apps/client/src/pages/maintenance/alerts.vue'),
        /* no children */
        meta: {
          "transition": "mobile-slide-right"
        }
      },
      {
        path: 'create-report',
        name: 'MaintenanceCreateReport',
        component: () => import('/app/apps/client/src/pages/maintenance/create-report.vue'),
        /* no children */
      },
      {
        path: 'machine',
        /* internal name: '/maintenance/machine' */
        /* no component */
        children: [
          {
            path: '',
            name: 'MaintenanceMachines',
            component: () => import('/app/apps/client/src/pages/maintenance/machine/index.vue'),
            /* no children */
            meta: {
              "transition": "mobile-slide-right"
            }
          },
          {
            path: ':id',
            name: 'MaintenanceMachineDetail',
            component: () => import('/app/apps/client/src/pages/maintenance/machine/[id].vue'),
            /* no children */
            meta: {
              "transition": "mobile-slide-right"
            }
          }
        ],
      },
      {
        path: 'profiles',
        name: 'MaintenanceProfiles',
        component: () => import('/app/apps/client/src/pages/maintenance/profiles.vue'),
        /* no children */
        meta: {
          "transition": "mobile-slide-right"
        }
      },
      {
        path: 'report-history',
        name: 'MaintenanceReportHistory',
        component: () => import('/app/apps/client/src/pages/maintenance/report-history.vue'),
        /* no children */
        meta: {
          "transition": "mobile-slide-right"
        }
      }
    ],
  },
  {
    path: '/management',
    name: 'Management',
    components: {
      'default': () => import('/app/apps/client/src/pages/management.vue'),
      'header': () => import('/app/apps/client/src/pages/management@header.vue')
    },
    children: [
      {
        path: '',
        name: 'ManagementHome',
        component: () => import('/app/apps/client/src/pages/management/index.vue'),
        /* no children */
      },
      {
        path: 'clusters',
        name: 'Clusters',
        component: () => import('/app/apps/client/src/pages/management/clusters.vue'),
        children: [
          {
            path: '',
            name: 'ClustersList',
            component: () => import('/app/apps/client/src/pages/management/clusters/index.vue'),
            /* no children */
          },
          {
            path: ':id',
            name: 'ClusterDetails',
            component: () => import('/app/apps/client/src/pages/management/clusters/[id].vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'users',
        name: 'Users',
        components: {
          'default': () => import('/app/apps/client/src/pages/management/users.vue'),
          'header': () => import('/app/apps/client/src/pages/management/users@header.vue')
        },
        children: [
          {
            path: '',
            name: 'UsersList',
            components: {
              'default': () => import('/app/apps/client/src/pages/management/users/index.vue'),
              'header': () => import('/app/apps/client/src/pages/management/users/index@header.vue')
            },
            /* no children */
            meta: {
              "transition": "mobile-slide-right"
            }
          },
          {
            path: ':id',
            name: 'UserDetails',
            component: () => import('/app/apps/client/src/pages/management/users/[id].vue'),
            /* no children */
            meta: {
              "transition": "mobile-slide-right"
            }
          }
        ],
      }
    ],
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: () => import('/app/apps/client/src/pages/notifications.vue'),
    /* no children */
    meta: {
      "transition": "mobile-slide-right"
    }
  },
  {
    path: '/partners',
    /* internal name: '/partners' */
    /* no component */
    children: [
      {
        path: 'callback',
        /* internal name: '/partners/callback' */
        /* no component */
        children: [
          {
            path: 'gocardless',
            name: 'GocardlessCallback',
            component: () => import('/app/apps/client/src/pages/partners/callback/gocardless.vue'),
            /* no children */
            meta: {
              "layout": "unauthenticated",
              "public": false,
              "transition": "slide-right"
            }
          }
        ],
      }
    ],
  },
  {
    path: '/phyto',
    name: 'PlantProtectionProductHome',
    component: () => import('/app/apps/client/src/pages/phyto.vue'),
    children: [
      {
        path: '',
        name: 'PlantProtectionProductList',
        component: () => import('/app/apps/client/src/pages/phyto/index.vue'),
        /* no children */
        meta: {
          "transition": "mobile-slide-right",
          "featureFlags": [
            "PHYTO_CATALOG"
          ]
        }
      },
      {
        path: ':id',
        name: 'PlantProtectionProductDetails',
        component: () => import('/app/apps/client/src/pages/phyto/[id].vue'),
        /* no children */
        meta: {
          "transition": "mobile-slide-right",
          "featureFlags": [
            "PHYTO_CATALOG"
          ]
        }
      },
      {
        path: 'mix',
        /* internal name: '/phyto/mix' */
        /* no component */
        children: [
          {
            path: '',
            name: 'PlantProtectionProductMixList',
            component: () => import('/app/apps/client/src/pages/phyto/mix/index.vue'),
            /* no children */
            meta: {
              "transition": "mobile-slide-right",
              "featureFlags": [
                "PHYTO_CATALOG"
              ]
            }
          },
          {
            path: 'create',
            name: 'PlantProtectionProductCreateMix',
            component: () => import('/app/apps/client/src/pages/phyto/mix/create.vue'),
            /* no children */
            meta: {
              "transition": "mobile-slide-right",
              "featureFlags": [
                "PHYTO_CATALOG"
              ]
            }
          }
        ],
      },
      {
        path: 'task',
        /* internal name: '/phyto/task' */
        /* no component */
        children: [
          {
            path: '',
            name: 'PlantProtectionProductTaskList',
            component: () => import('/app/apps/client/src/pages/phyto/task/index.vue'),
            /* no children */
            meta: {
              "transition": "mobile-slide-right",
              "featureFlags": [
                "PHYTO_CATALOG"
              ]
            }
          },
          {
            path: 'create',
            name: 'PlantProtectionProductCreateTask',
            component: () => import('/app/apps/client/src/pages/phyto/task/create.vue'),
            /* no children */
            meta: {
              "transition": "mobile-slide-right",
              "featureFlags": [
                "PHYTO_CATALOG"
              ]
            }
          }
        ],
      }
    ],
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('/app/apps/client/src/pages/profile.vue'),
    /* no children */
  },
  {
    path: '/reports',
    /* internal name: '/reports' */
    /* no component */
    children: [
      {
        path: '',
        name: 'Reports',
        component: () => import('/app/apps/client/src/pages/reports/index.vue'),
        /* no children */
        meta: {
          "featureFlags": [
            "STATIC_DASHBOARDS"
          ],
          "transition": "mobile-slide-right"
        }
      },
      {
        path: ':id',
        name: 'ReportDetails',
        component: () => import('/app/apps/client/src/pages/reports/[id].vue'),
        /* no children */
        meta: {
          "featureFlags": [
            "STATIC_DASHBOARDS"
          ],
          "transition": "mobile-slide-right"
        }
      }
    ],
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('/app/apps/client/src/pages/reset-password.vue'),
    /* no children */
    meta: {
      "layout": "unauthenticated",
      "transition": "slide-left",
      "public": true
    }
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('/app/apps/client/src/pages/signup.vue'),
    /* no children */
    meta: {
      "layout": "unauthenticated",
      "public": true,
      "transition": "slide-right"
    }
  },
  {
    path: '/style_guide',
    name: 'StyleGuide',
    component: () => import('/app/apps/client/src/pages/style_guide.vue'),
    /* no children */
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

