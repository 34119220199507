import { type contract, isDefined } from '@samsys/shared';
import type { TsRestClient } from './ts-rest.service';
import {
  apiHandler,
  type InferBody,
  type InferFlatRequest,
  type InferQuery
} from '@/utils/ts-rest';
import type { Feature, Polygon } from 'geojson';
import type JSZip from 'jszip';

class TelepacParseError extends Error {}
const TELEPAC_NS = 'http://www.opengis.net/gml';

const PRJ_CONTENT_FIX_IDENTIFIER =
  'PROJCS["RGF93 / Lambert-93",GEOGCS["RGF93",DATUM["Réseau géodésique français 1993",SPHEROID["GRS 1980",6378137.0,298.257222101,AUTHORITY["EPSG","7019"]],TOWGS84[0,0,0,0,0,0,0],AUTHORITY["EPSG","6171"]],PRIMEM["Greenwich",0.0,AUTHORITY["EPSG","8901"]]],PROJECTION["Lambert Conic Conformal (2SP)"],PARAMETER["latitude of origin",46.5],PARAMETER["standard parallel 1",49],PARAMETER["standard parallel 2",44],PARAMETER["central meridian",3],PARAMETER["scale factor",1],PARAMETER["false easting",700000],PARAMETER["false northing",6600000],UNIT["meter",1,AUTHORITY["EPSG","9001"]],AXIS["Easting",EAST],AXIS["Northing",NORTH],AUTHORITY["EPSG","2154"]]';

export const createFieldService = ({
  tsRestClient
}: {
  tsRestClient: TsRestClient;
}) => {
  const lambert93toWGPS = (
    lambertE: number,
    lambertN: number
  ): [number, number] => {
    const constantes = {
      GRS80E: 0.081819191042816,
      LONG_0: 3,
      XS: 700000,
      YS: 12655612.0499,
      n: 0.725607765053267,
      C: 11754255.4261
    };

    const delX = lambertE - constantes.XS;
    const delY = lambertN - constantes.YS;
    const gamma = Math.atan(-delX / delY);
    const R = Math.sqrt(delX * delX + delY * delY);
    const latiso = Math.log(constantes.C / R) / constantes.n;
    const sinPhiit0 = Math.tanh(
      latiso + constantes.GRS80E * Math.atanh(constantes.GRS80E * Math.sin(1))
    );
    const sinPhiit1 = Math.tanh(
      latiso + constantes.GRS80E * Math.atanh(constantes.GRS80E * sinPhiit0)
    );
    const sinPhiit2 = Math.tanh(
      latiso + constantes.GRS80E * Math.atanh(constantes.GRS80E * sinPhiit1)
    );
    const sinPhiit3 = Math.tanh(
      latiso + constantes.GRS80E * Math.atanh(constantes.GRS80E * sinPhiit2)
    );
    const sinPhiit4 = Math.tanh(
      latiso + constantes.GRS80E * Math.atanh(constantes.GRS80E * sinPhiit3)
    );
    const sinPhiit5 = Math.tanh(
      latiso + constantes.GRS80E * Math.atanh(constantes.GRS80E * sinPhiit4)
    );
    const sinPhiit6 = Math.tanh(
      latiso + constantes.GRS80E * Math.atanh(constantes.GRS80E * sinPhiit5)
    );

    const longRad = Math.asin(sinPhiit6);
    const latRad = gamma / constantes.n + (constantes.LONG_0 / 180) * Math.PI;

    const longitude = (latRad / Math.PI) * 180;
    const latitude = (longRad / Math.PI) * 180;

    return [longitude, latitude];
  };

  /**
   * Apply a fix to shp files that are missing some metadata about encoding
   * we assume the file is using lambert93 referential
   */
  const fixShpLambert93 = async (zip: JSZip): Promise<ArrayBuffer> => {
    let prj = '';

    zip.forEach((relativePath, zipEntry) => {
      if (zipEntry.name.includes('.prj')) {
        prj = zipEntry.name;
      }
    });

    if (prj) {
      const prjContent = await zip.file(prj)!.async('string');

      if (prjContent === PRJ_CONTENT_FIX_IDENTIFIER) {
        zip.file(
          prj,
          `PROJCS["RGF93 / Lambert-93",
            GEOGCS["RGF93",
                DATUM["Reseau_Geodesique_Francais_1993",
                    SPHEROID["GRS 1980",6378137,298.257222101,
                        AUTHORITY["EPSG","7019"]],
                    TOWGS84[0,0,0,0,0,0,0],
                    AUTHORITY["EPSG","6171"]],
                PRIMEM["Greenwich",0,
                    AUTHORITY["EPSG","8901"]],
                UNIT["degree",0.0174532925199433,
                    AUTHORITY["EPSG","9122"]],
                AUTHORITY["EPSG","4171"]],
            PROJECTION["Lambert_Conformal_Conic_2SP"],
            PARAMETER["standard_parallel_1",49],
            PARAMETER["standard_parallel_2",44],
            PARAMETER["latitude_of_origin",46.5],
            PARAMETER["central_meridian",3],
            PARAMETER["false_easting",700000],
            PARAMETER["false_northing",6600000],
            UNIT["metre",1,
                AUTHORITY["EPSG","9001"]],
            AXIS["X",EAST],
            AXIS["Y",NORTH],
            AUTHORITY["EPSG","2154"]]`
        );
      }
    }

    return await zip.generateAsync({ type: 'arraybuffer' });
  };

  const getCoordinatesFromTelepacBoundary = (boundary: Element) => {
    const children = boundary.getElementsByTagNameNS(TELEPAC_NS, 'coordinates');
    if (!children || !children[0]) throw new TelepacParseError();
    const node = children[0].childNodes[0];
    if (!node) throw new TelepacParseError();

    const res = node
      .nodeValue!.split(' ')
      .map(str => str.split(','))
      .filter(str => str.length === 2)
      .map(coord =>
        lambert93toWGPS(parseFloat(coord[0]!), parseFloat(coord[1]!))
      );
    return res;
  };

  return {
    getAllByUserIds(query: InferQuery<typeof contract.field.byUserIds>) {
      return apiHandler(tsRestClient.field.byUserIds, { query });
    },

    getAvailableTags() {
      return apiHandler(tsRestClient.field.tags, {});
    },

    getAvailableHashtags() {
      return apiHandler(tsRestClient.field.hashtags, {});
    },

    async create(body: InferFlatRequest<typeof contract.field.create>) {
      return apiHandler(tsRestClient.field.create, { body });
    },

    async update({
      fieldId,
      ...body
    }: InferFlatRequest<typeof contract.field.edit>) {
      return apiHandler(tsRestClient.field.edit, { params: { fieldId }, body });
    },

    editHashtags({
      fieldId,
      ...body
    }: InferFlatRequest<typeof contract.field.editHashtags>) {
      return apiHandler(tsRestClient.field.editHashtags, {
        params: { fieldId },
        body
      });
    },

    async close(body: InferFlatRequest<typeof contract.field.close>) {
      return apiHandler(tsRestClient.field.close, { body });
    },

    async delete(body: InferFlatRequest<typeof contract.field.delete>) {
      return apiHandler(tsRestClient.field.delete, { body });
    },

    async split({
      fieldId,
      ...body
    }: InferFlatRequest<typeof contract.field.split>) {
      return apiHandler(tsRestClient.field.split, {
        params: { fieldId },
        body
      });
    },

    async combine(body: InferBody<typeof contract.field.combine>) {
      return apiHandler(tsRestClient.field.combine, {
        body
      });
    },

    async changeOwnership(
      body: InferFlatRequest<typeof contract.field.changeOwnership>
    ) {
      return apiHandler(tsRestClient.field.changeOwnership, {
        body
      });
    },

    processTelepac(raw: string) {
      const telepacFields: Feature<Polygon>[] = [];
      const xmlDoc = new DOMParser().parseFromString(raw, 'text/xml');
      const ilots = xmlDoc.getElementsByTagName('ilot');

      if (!ilots) throw new TelepacParseError();

      for (const ilot of ilots) {
        const parcelles = ilot.getElementsByTagName('parcelle');
        for (const parcelle of parcelles) {
          const polygons = parcelle.getElementsByTagNameNS(
            TELEPAC_NS,
            'Polygon'
          );
          const polygon = polygons[0];
          if (!polygon) throw new TelepacParseError();
          const innerBoundary = polygon.getElementsByTagNameNS(
            TELEPAC_NS,
            'innerBoundaryIs'
          )[0];
          const outerBoundary = polygon.getElementsByTagNameNS(
            TELEPAC_NS,
            'outerBoundaryIs'
          )[0];

          const coordinatesInner: [number, number][] = [];
          const coordinates: [number, number][] = [];

          if (innerBoundary) {
            coordinatesInner.push(
              ...getCoordinatesFromTelepacBoundary(innerBoundary)
            );
          }
          if (outerBoundary) {
            coordinates.push(
              ...getCoordinatesFromTelepacBoundary(outerBoundary)
            );
          }

          const feature: Feature<Polygon> = {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [coordinates]
            },
            properties: {}
          };

          if (coordinatesInner.length) {
            feature.geometry.coordinates.push(coordinatesInner);
          }

          telepacFields.push(feature);
        }
      }

      return telepacFields;
    },

    async processZip(zipFile: ArrayBuffer) {
      const JSZip = await import('jszip');
      const buffer = await fixShpLambert93(await JSZip.loadAsync(zipFile));

      const shp = await import('shpjs');
      const geojson = await shp.parseZip(buffer);
      if (Array.isArray(geojson)) return [];

      if (!geojson.features.length) return [];

      const polygons = geojson.features
        .map(elem => {
          switch (elem.geometry.type) {
            case 'MultiPolygon':
              return elem.geometry.coordinates.map(polygon => {
                return {
                  type: 'Feature',
                  geometry: {
                    coordinates: polygon,
                    type: 'Polygon'
                  },
                  properties: {}
                } as Feature<Polygon>;
              });
            case 'Polygon':
              return elem as Feature<Polygon>;
            default:
              return null;
          }
        })
        .flat()
        .filter(isDefined);

      const isLambert = polygons.some(
        polygon =>
          Math.abs(polygon.geometry.coordinates[0]?.[0]?.[0] ?? 0) > 180
      );

      if (isLambert) {
        polygons.forEach(polygon => {
          polygon.geometry.coordinates.forEach(coordinate => {
            coordinate.forEach((point, pIndex) => {
              coordinate[pIndex] = lambert93toWGPS(point[0]!, point[1]!);
            });
          });
        });
      }

      return polygons;
    }
  };
};
