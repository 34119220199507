<script setup lang="ts">
import type { RouterLinkProps } from 'vue-router/auto';

defineOptions({
  name: 'UiIconButton'
});

const props = withDefaults(
  defineProps<{
    variant?: 'full' | 'outlined' | 'ghost' | 'light';
    to?: RouterLinkProps['to'];
    icon: string;
    title: string;
    size?: 'sm' | 'md' | 'lg' | 'xl';
    isLoading?: boolean;
    rounded?: boolean;
  }>(),
  {
    variant: 'ghost',
    to: undefined,
    size: 'md',
    rounded: true,
    showTitle: false
  }
);

const buttonProps = computed(() => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { icon, rounded, ...rest } = props;
  return rest;
});
</script>

<template>
  <UiButton
    class="ui-icon-button"
    :class="props.rounded && 'is-rounded'"
    v-bind="buttonProps"
    :aria-label="props.title"
  >
    <UiIcon :icon="props.icon" :size="props.size" />
  </UiButton>
</template>

<style scoped lang="postcss">
.ui-button.ui-icon-button {
  padding: var(--size-1);
  &.ui-button--sm {
    padding: calc(0.5 * var(--size-1));
    & > span > * {
      width: 1.25em;
      height: 1.25em;
    }
  }
  &.is-rounded {
    border-radius: var(--radius-round);
  }

  & > span > * {
    width: 1.5em;
    height: 1.5em;
  }
}

.title {
  font-weight: var(--font-weight-4);
  font-size: var(--font-size-0);
}
</style>
