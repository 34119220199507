<script setup lang="ts">
import type { StandardLonghandProperties } from 'csstype';

defineOptions({
  name: 'UiFlex'
});

const props = withDefaults(
  defineProps<{
    as?: string;
    justify?: StandardLonghandProperties['justifyContent'];
    align?: StandardLonghandProperties['alignItems'];
    wrap?: StandardLonghandProperties['flexWrap'];
    direction?: StandardLonghandProperties['flexDirection'];
    gap?:
      | '0'
      | '1'
      | '2'
      | '3'
      | '4'
      | '5'
      | '6'
      | '7'
      | '8'
      | '9'
      | '10'
      | '11'
      | '12'
      | '13'
      | '14'
      | '15';
    inline?: boolean;
  }>(),
  {
    as: 'div',
    justify: 'initial',
    align: 'initial',
    wrap: 'initial',
    direction: 'initial',
    gap: '0'
  }
);

const display = computed(() => (props.inline ? 'inline-flex' : 'flex'));
</script>

<template>
  <component :is="props.as" class="ui-flex"><slot /></component>
</template>

<style scoped lang="postcss">
.ui-flex {
  display: v-bind(display);
  justify-content: v-bind('props.justify');
  align-items: v-bind('props.align');
  flex-direction: v-bind('props.direction');
  flex-wrap: v-bind('props.wrap');
  gap: v-bind('`var(--size-${props.gap})`');
}
</style>
