import { contract } from '@samsys/shared';
import type { TsRestClient } from './ts-rest.service';
import {
  apiHandler,
  type InferBody,
  type InferFlatRequest,
  type InferQuery
} from '@/utils/ts-rest';
import { match } from 'ts-pattern';
// import type { ClientInferResponseBody } from '@ts-rest/core';

export type CreateActivityServiceOptions = { tsRestClient: TsRestClient };

export const MULTI_VALUE_TSDATADEF_IDS = ['662ab917ce1e9d73de4891ca'];

export const createActivityService = ({
  tsRestClient
}: CreateActivityServiceOptions) => {
  const parseTsDatadefMultiValue = (value: number) => {
    // const value = 4294964565;

    let toBinarySttring = value.toString(2);

    // if length of toBinarySttring is not odd, add zero to the left
    if (toBinarySttring.length % 2 !== 0) {
      toBinarySttring = `0${toBinarySttring}`;
    }

    const tronconEnable: number[] = [];

    for (let i = 0; i < toBinarySttring.length; i += 2) {
      const tps = (toBinarySttring[i]! + toBinarySttring[i + 1]!) as
        | '00'
        | '01'
        | '10'
        | '11';
      match(tps)
        .with('00', () => {
          tronconEnable.unshift(0);
        })
        .with('01', () => {
          tronconEnable.unshift(1);
        })
        .with('10', () => {
          tronconEnable.unshift(-1);
        })
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .with('11', () => {})
        .otherwise(tps => {
          console.log(tps);
        });
    }

    return tronconEnable;
  };
  return {
    parseTsDatadefMultiValue,

    async getAll(body: InferFlatRequest<typeof contract.activity.all>) {
      const activities = await apiHandler(tsRestClient.activity.all, { body });

      return activities;
    },

    async getTsDataDef(body: InferBody<typeof contract.activity.tsDataDefs>) {
      const response = await apiHandler(tsRestClient.activity.tsDataDefs, {
        body
      });

      if (MULTI_VALUE_TSDATADEF_IDS.includes(body.tsDatadefId)) {
        Object.entries(response).forEach(([key, values]) => {
          const parsed = values.map(val => parseTsDatadefMultiValue(val[1]));
          const maxValues = Math.max(...parsed.map(val => val.length));
          response[key] = values.filter(
            (val, index) => parsed[index]!.length === maxValues
          );
        });
      }

      const result: Record<string, Array<{ t: number; value: number }>> = {};

      Object.entries(response).forEach(([key, value]) => {
        result[key] = value.map(([t, value]) => ({ t, value }));
      });
      return result;
    },

    getTsDataDefStats(
      query: InferQuery<typeof contract.activity.tsDataDefsStats>
    ) {
      return apiHandler(tsRestClient.activity.tsDataDefsStats, { query });
    },

    export(opts: InferBody<typeof contract.activity.export>) {
      return apiHandler(tsRestClient.activity.export, { body: opts });
    },

    exportTsDataDefStats(
      body: InferBody<typeof contract.activity.exportTsDatadefsStats>
    ) {
      return apiHandler(tsRestClient.activity.exportTsDatadefsStats, { body });
    },

    assignClient(body: InferBody<typeof contract.activity.assignClient>) {
      return apiHandler(tsRestClient.activity.assignClient, { body });
    },

    assignMachines(body: InferBody<typeof contract.activity.assignMachines>) {
      return apiHandler(tsRestClient.activity.assignMachines, { body });
    },

    getAllAssignments(
      query: InferFlatRequest<typeof contract.activity.assignments>
    ) {
      return apiHandler(tsRestClient.activity.assignments, { query });
    },

    deleteAssignments(
      requests: Array<
        InferFlatRequest<typeof contract.activity.deleteAssignment>
      >
    ) {
      return Promise.all(
        requests.map(req =>
          apiHandler(tsRestClient.activity.deleteAssignment, { params: req })
        )
      );
    },

    async getLiveMachines(
      body: InferFlatRequest<typeof contract.activity.liveMachines>
    ) {
      const machine = await apiHandler(tsRestClient.activity.liveMachines, {
        body
      });

      return machine;
    }
  };
};
