import { urlBase64ToUint8Array } from '@/utils/helpers';
import { matchByQueryKeyPredicate } from '@/utils/queryClient';
import { useRegisterSW } from 'virtual:pwa-register/vue';

const supportsNotifications = () =>
  'Notification' in window &&
  'serviceWorker' in navigator &&
  'PushManager' in window;

export const usePushNotifications = () => {
  if (!supportsNotifications()) {
    return {
      permission: ref(false),
      pushSubscription: ref<PushSubscription | undefined>(undefined),
      isEnabled: ref(false),
      enable: () => {},
      disable: async () => {},
      isLoading: ref(false),
      isAvailable: ref(false)
    };
  }

  const { data: me } = useMe();
  const swRegistration = ref<ServiceWorkerRegistration>();
  const api = useApi();
  const qk = useQueryKeys();
  const qc = useQueryClient();
  const isEnabled = ref(false);

  const { isSupported } = useWebNotification({ requestPermissions: false });
  const permission = ref(window.Notification.permission);
  const pushSubscription = ref<PushSubscription | undefined>(undefined);

  useRegisterSW({
    async onRegistered(registration) {
      if (!registration) return;
      swRegistration.value = registration;
      pushSubscription.value =
        (await registration.pushManager.getSubscription()) ?? undefined;
    }
  });

  const stopWatching = watchEffect(() => {
    if (!me.value) return;
    if (!pushSubscription.value) return;
    isEnabled.value =
      me.value?.pushSubscriptions.some(
        endpoint => endpoint === pushSubscription.value?.endpoint
      ) ?? false;
    stopWatching();
  });

  const { mutate: sendPushSubscription, isLoading: isSendingSubscription } =
    useMutation({
      mutationFn: api.notificationService.enablePush,
      onSuccess() {
        isEnabled.value = true;
        qc.invalidateQueries({
          predicate: matchByQueryKeyPredicate(qk.users.me.queryKey)
        });
      },
      onError(err) {
        console.error(err);
        showDanger({
          title: 'Une erreur est survenue.',
          placement: 'bottom',
          text: "Les notifications push n'ont pas pu être activées."
        });
      }
    });

  const {
    mutate: disablePushSubscription,
    isLoading: isDisablingSubscription
  } = useMutation({
    mutationFn: api.notificationService.disablePush,
    onSuccess() {
      isEnabled.value = false;
      qc.invalidateQueries({
        predicate: matchByQueryKeyPredicate(qk.users.me.queryKey)
      });
    }
  });

  const isAskingPermission = ref(false);

  const askPermission = () => {
    return new Promise(function (resolve, reject) {
      isAskingPermission.value = true;

      const onSuccess = (result: NotificationPermission) => {
        permission.value = result;
        isAskingPermission.value = false;
        resolve(result);
      };

      // We need to handle callback and promise style because of browser inconsistencies
      const permissionResult = Notification.requestPermission(onSuccess);

      if (permissionResult) {
        permissionResult.then(onSuccess, reject);
      }
    });
  };
  const { showDanger } = useToast();

  const getSubscriptionOrCreate = async () => {
    if (pushSubscription.value) return pushSubscription.value;

    return await swRegistration.value!.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(
        import.meta.env.VITE_VAPID_PUBLIC_KEY
      )
    });
  };
  const enablePushNotifications = async () => {
    await askPermission();
    try {
      if (!swRegistration.value) {
        throw new Error('Service worker Registration is unavailable');
      }
      const subscription = await getSubscriptionOrCreate();
      const keys = JSON.parse(JSON.stringify(subscription)).keys;

      sendPushSubscription({
        endpoint: subscription.endpoint,
        keys
      });
    } catch (err) {
      showDanger({
        title: 'Une erreur est survenue.',
        placement: 'bottom',
        text: "Les notifications push n'ont pas pu être activées."
      });
    }
  };

  return {
    permission,
    pushSubscription,
    isEnabled,
    enable: enablePushNotifications,
    disable: async () => {
      const subscription =
        await swRegistration.value!.pushManager.getSubscription();
      if (!subscription) return;
      disablePushSubscription({ endpoint: subscription.endpoint });
    },
    isLoading: computed(
      () =>
        isSendingSubscription.value ||
        isAskingPermission.value ||
        isDisablingSubscription.value
    ),
    isAvailable: computed(() => !!swRegistration.value && isSupported.value)
  };
};
